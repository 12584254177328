<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">妙</span>典三丁目店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >妙典駅から徒歩5分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市妙典3丁目22-15
                            ハイムウリンボー102</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1673319458275!6m8!1m7!1sNRQ4XZq0qWrJRBunlQDK1A!2m2!1d35.69343326836293!2d139.9270219954983!3f22.550307896527254!4f8.306905248496548!5f0.7820865974627469"
                            width="100%"
                            height="360"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">妙典駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て、真っ直ぐ高架線下を進み、突き当りまで行きます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            はなの舞の左側（ロータリーと逆側）の出口を出ます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                扉を出たら右側へ高架線沿いを真っすぐ進みます。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            1つ目の交差点も高架線に沿って真っすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            美容室Lauleaさんの木目の看板がついている建物の1階です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            美容室Lauleaさんの右隣の扉がREVOIST妙典三丁目店になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの11号店です！
妙典駅から徒歩5分の高架線沿いで、イオンからも近く、妙典2丁目や3丁目にお住まいの方にとって通いやすいジムです！
運動初心者の方でも会話がしやすく、身体の悩みを相談しやすいパ―ソナルトレーナーが、ダイエットのサポートや姿勢改善をその方にとって無理のない動きからレッスンします！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '妙典三丁目店',
            center: {
                lat: 35.6937761616099,
                lng: 139.92704371219932,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.6937761616099,
                        lng: 139.92704371219932,
                    },
                    title: '妙典三丁目店',
                },
            ],
            message: `パーソナルジム REVOISTの11号店です！<br />
妙典駅から徒歩5分の高架線沿いで、イオンからも近く、妙典2丁目や3丁目にお住まいの方にとって通いやすいジムです！<br />
運動初心者の方でも会話がしやすく、身体の悩みを相談しやすいパ―ソナルトレーナーが、ダイエットのサポートや姿勢改善をその方にとって無理のない動きからレッスンします！<br />
<br /><br />
トレーニングの習慣化によって、昨日の自分を少しでも超えた先には達成感があり、心が前向きになることで人生を豊かにしてくれます！<br />
是非、僕と楽しみながらトレーニングしましょう！<br />
妙典三丁目店トレーナー：佐野良樹
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '妙典三丁目店',
                    disabled: true,
                    href: 'access-11',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-02.JPG'
                : 'static/pc_access_11/Myoden3Chome-02.JPG'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-00.png'
                : 'static/pc_access_11/Myoden3Chome-00.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-06.JPG'
                : 'static/pc_access_11/Myoden3Chome-06.JPG'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-05.JPG'
                : 'static/pc_access_11/Myoden3Chome-05.JPG'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-04.JPG'
                : 'static/pc_access_11/Myoden3Chome-04.JPG'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-03.JPG'
                : 'static/pc_access_11/Myoden3Chome-03.JPG'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-02.JPG'
                : 'static/pc_access_11/Myoden3Chome-02.JPG'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_11/Myoden3Chome-01.JPG'
                : 'static/pc_access_11/Myoden3Chome-01.JPG'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/妙典三丁目店-佐野良樹トレーナー01.jpg'
                : 'static/landing/trainer/妙典三丁目店-佐野良樹トレーナー01.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
